module.exports = [
  {
    id: '臺北市',
    name: 'Taipei City'
  },
  {
    id: '基隆市',
    name: 'Keelung City'
  },
  {
    id: '新北市',
    name: 'New Taipei City'
  },
  {
    id: '宜蘭縣',
    name: 'Yilan County'
  },
  {
    id: '新竹市',
    name: 'Hsinchu City'
  },
  {
    id: '新竹縣',
    name: 'HsinChu County'
  },
  {
    id: '桃園市',
    name: 'Taoyuan City'
  },
  {
    id: '苗栗縣',
    name: 'Miaoli County'
  },
  {
    id: '臺中市',
    name: 'Taichung City'
  },
  {
    id: '彰化縣',
    name: 'Changhua County'
  },
  {
    id: '南投縣',
    name: 'Nantou County'
  },
  {
    id: '嘉義市',
    name: 'Chiayi City'
  },
  {
    id: '嘉義縣',
    name: 'Chiayi County'
  },
  {
    id: '雲林縣',
    name: 'Yunlin County'
  },
  {
    id: '臺南市',
    name: 'Tainan City'
  },
  {
    id: '高雄市',
    name: 'Kaohsiung City'
  },
  // {
  //   id: '澎湖縣',
  //   name: 'Penghu County'
  // },
  {
    id: '屏東縣',
    name: 'Pingtung County'
  },
  {
    id: '臺東縣',
    name: 'Taitung County'
  },
  {
    id: '花蓮縣',
    name: 'Hualien County'
  },
  // {
  //   id: '金門縣',
  //   name: 'Kinmen County'
  // },
  // {
  //   id: '連江縣',
  //   name: 'Lienchiang County'
  // }
]
