module.exports = [
    { 'id': 100, 'county': '臺北市', 'city': '中正區', 'AreaEngName': 'Zhongzheng Dist.' },
    { 'id': 103, 'county': '臺北市', 'city': '大同區', 'AreaEngName': 'Datong Dist.' },
    { 'id': 104, 'county': '臺北市', 'city': '中山區', 'AreaEngName': 'Zhongshan Dist.' },
    { 'id': 105, 'county': '臺北市', 'city': '松山區', 'AreaEngName': 'Songshan Dist.' },
    { 'id': 106, 'county': '臺北市', 'city': '大安區', 'AreaEngName': 'Da’an Dist.' },
    { 'id': 108, 'county': '臺北市', 'city': '萬華區', 'AreaEngName': 'Wanhua Dist.' },
    { 'id': 110, 'county': '臺北市', 'city': '信義區', 'AreaEngName': 'Xinyi Dist.' },
    { 'id': 111, 'county': '臺北市', 'city': '士林區', 'AreaEngName': 'Shilin Dist.' },
    { 'id': 112, 'county': '臺北市', 'city': '北投區', 'AreaEngName': 'Beitou Dist.' },
    { 'id': 114, 'county': '臺北市', 'city': '內湖區', 'AreaEngName': 'Neihu Dist.' },
    { 'id': 115, 'county': '臺北市', 'city': '南港區', 'AreaEngName': 'Nangang Dist.' },
    { 'id': 116, 'county': '臺北市', 'city': '文山區', 'AreaEngName': 'Wenshan Dist.' },

    { 'id': 200, 'county': '基隆市', 'city': '仁愛區', 'AreaEngName': 'Ren’ai Dist.' },
    { 'id': 201, 'county': '基隆市', 'city': '信義區', 'AreaEngName': 'Xinyi Dist.' },
    { 'id': 202, 'county': '基隆市', 'city': '中正區', 'AreaEngName': 'Zhongzheng Dist.' },
    { 'id': 203, 'county': '基隆市', 'city': '中山區', 'AreaEngName': 'Zhongshan Dist.' },
    { 'id': 204, 'county': '基隆市', 'city': '安樂區', 'AreaEngName': 'Anle Dist.' },
    { 'id': 205, 'county': '基隆市', 'city': '暖暖區', 'AreaEngName': 'Nuannuan Dist.' },
    { 'id': 206, 'county': '基隆市', 'city': '七堵區', 'AreaEngName': 'Qidu Dist.' },

    { 'id': 207, 'county': '新北市', 'city': '萬里區', 'AreaEngName': 'Wanli Dist.' },
    { 'id': 208, 'county': '新北市', 'city': '金山區', 'AreaEngName': 'Jinshan Dist.' },
    { 'id': 220, 'county': '新北市', 'city': '板橋區', 'AreaEngName': 'Banqiao Dist.' },
    { 'id': 221, 'county': '新北市', 'city': '汐止區', 'AreaEngName': 'Xizhi Dist.' },
    { 'id': 222, 'county': '新北市', 'city': '深坑區', 'AreaEngName': 'Shenkeng Dist.' },
    { 'id': 223, 'county': '新北市', 'city': '石碇區', 'AreaEngName': 'Shiding Dist.' },
    { 'id': 224, 'county': '新北市', 'city': '瑞芳區', 'AreaEngName': 'Ruifang Dist.' },
    { 'id': 226, 'county': '新北市', 'city': '平溪區', 'AreaEngName': 'Pingxi Dist.' },
    { 'id': 227, 'county': '新北市', 'city': '雙溪區', 'AreaEngName': 'Shuangxi Dist.' },
    { 'id': 228, 'county': '新北市', 'city': '貢寮區', 'AreaEngName': 'Gongliao Dist.' },
    { 'id': 231, 'county': '新北市', 'city': '新店區', 'AreaEngName': 'Xindian Dist.' },
    { 'id': 232, 'county': '新北市', 'city': '坪林區', 'AreaEngName': 'Pinglin Dist.' },
    { 'id': 233, 'county': '新北市', 'city': '烏來區', 'AreaEngName': 'Wulai Dist.' },
    { 'id': 234, 'county': '新北市', 'city': '永和區', 'AreaEngName': 'Yonghe Dist.' },
    { 'id': 235, 'county': '新北市', 'city': '中和區', 'AreaEngName': 'Zhonghe Dist.' },
    { 'id': 236, 'county': '新北市', 'city': '土城區', 'AreaEngName': 'Tucheng Dist.' },
    { 'id': 237, 'county': '新北市', 'city': '三峽區', 'AreaEngName': 'Sanxia Dist.' },
    { 'id': 238, 'county': '新北市', 'city': '樹林區', 'AreaEngName': 'Shulin Dist.' },
    { 'id': 239, 'county': '新北市', 'city': '鶯歌區', 'AreaEngName': 'Yingge Dist.' },
    { 'id': 241, 'county': '新北市', 'city': '三重區', 'AreaEngName': 'Sanchong Dist.' },
    { 'id': 242, 'county': '新北市', 'city': '新莊區', 'AreaEngName': 'Xinzhuang Dist.' },
    { 'id': 243, 'county': '新北市', 'city': '泰山區', 'AreaEngName': 'Taishan Dist.' },
    { 'id': 244, 'county': '新北市', 'city': '林口區', 'AreaEngName': 'Linkou Dist.' },
    { 'id': 247, 'county': '新北市', 'city': '蘆洲區', 'AreaEngName': 'Luzhou Dist.' },
    { 'id': 248, 'county': '新北市', 'city': '五股區', 'AreaEngName': 'Wugu Dist.' },
    { 'id': 249, 'county': '新北市', 'city': '八里區', 'AreaEngName': 'Bali Dist.' },
    { 'id': 251, 'county': '新北市', 'city': '淡水區', 'AreaEngName': 'Tamsui Dist.' },
    { 'id': 252, 'county': '新北市', 'city': '三芝區', 'AreaEngName': 'Sanzhi Dist.' },
    { 'id': 253, 'county': '新北市', 'city': '石門區', 'AreaEngName': 'Shimen Dist.' },

    { 'id': 260, 'county': '宜蘭縣', 'city': '宜蘭市', 'AreaEngName': 'Yilan City' },
    { 'id': 263, 'county': '宜蘭縣', 'city': '壯圍鄉', 'AreaEngName': 'Zhuangwei Township' },
    { 'id': 261, 'county': '宜蘭縣', 'city': '頭城鎮', 'AreaEngName': 'Toucheng Township' },
    { 'id': 262, 'county': '宜蘭縣', 'city': '礁溪鄉', 'AreaEngName': 'Jiaoxi Township' },
    { 'id': 264, 'county': '宜蘭縣', 'city': '員山鄉', 'AreaEngName': 'Yuanshan Township' },
    { 'id': 265, 'county': '宜蘭縣', 'city': '羅東鎮', 'AreaEngName': 'Luodong Township' },
    { 'id': 266, 'county': '宜蘭縣', 'city': '三星鄉', 'AreaEngName': 'Sanxing Township' },
    { 'id': 267, 'county': '宜蘭縣', 'city': '大同鄉', 'AreaEngName': 'Datong Township' },
    { 'id': 268, 'county': '宜蘭縣', 'city': '五結鄉', 'AreaEngName': 'Wujie Township' },
    { 'id': 269, 'county': '宜蘭縣', 'city': '冬山鄉', 'AreaEngName': 'Dongshan Township' },
    { 'id': 270, 'county': '宜蘭縣', 'city': '蘇澳鎮', 'AreaEngName': 'Su’ao Township' },
    { 'id': 272, 'county': '宜蘭縣', 'city': '南澳鄉', 'AreaEngName': 'Nan’ao Township' },

    { 'id': 300, 'county': '新竹市', 'city': '東區', 'AreaEngName': 'East Dist.' },
    { 'id': 300, 'county': '新竹市', 'city': '北區', 'AreaEngName': 'North Dist.' },
    { 'id': 300, 'county': '新竹市', 'city': '香山區', 'AreaEngName': 'Xiangshan Dist.' },

    { 'id': 308, 'county': '新竹縣', 'city': '寶山鄉', 'AreaEngName': 'Baoshan Township' },
    { 'id': 302, 'county': '新竹縣', 'city': '竹北市', 'AreaEngName': 'Zhubei City' },
    { 'id': 303, 'county': '新竹縣', 'city': '湖口鄉', 'AreaEngName': 'Hukou Township' },
    { 'id': 304, 'county': '新竹縣', 'city': '新豐鄉', 'AreaEngName': 'Xinfeng Township' },
    { 'id': 305, 'county': '新竹縣', 'city': '新埔鎮', 'AreaEngName': 'Xinpu Township' },
    { 'id': 306, 'county': '新竹縣', 'city': '關西鎮', 'AreaEngName': 'Guanxi Township' },
    { 'id': 307, 'county': '新竹縣', 'city': '芎林鄉', 'AreaEngName': 'Qionglin Township' },
    { 'id': 310, 'county': '新竹縣', 'city': '竹東鎮', 'AreaEngName': 'Zhudong Township' },
    { 'id': 311, 'county': '新竹縣', 'city': '五峰鄉', 'AreaEngName': 'Wufeng Township' },
    { 'id': 312, 'county': '新竹縣', 'city': '橫山鄉', 'AreaEngName': 'Hengshan Township' },
    { 'id': 313, 'county': '新竹縣', 'city': '尖石鄉', 'AreaEngName': 'Jianshi Township' },
    { 'id': 314, 'county': '新竹縣', 'city': '北埔鄉', 'AreaEngName': 'Beipu Township' },
    { 'id': 315, 'county': '新竹縣', 'city': '峨眉鄉', 'AreaEngName': 'Emei Township' },

    { 'id': 320, 'county': '桃園市', 'city': '中壢區', 'AreaEngName': 'Zhongli Dist.' },
    { 'id': 324, 'county': '桃園市', 'city': '平鎮區', 'AreaEngName': 'Pingzhen Dist.' },
    { 'id': 325, 'county': '桃園市', 'city': '龍潭區', 'AreaEngName': 'Longtan Dist.' },
    { 'id': 326, 'county': '桃園市', 'city': '楊梅區', 'AreaEngName': 'Yangmei Dist.' },
    { 'id': 327, 'county': '桃園市', 'city': '新屋區', 'AreaEngName': 'Xinwu Dist.' },
    { 'id': 328, 'county': '桃園市', 'city': '觀音區', 'AreaEngName': 'Guanyin Dist.' },
    { 'id': 330, 'county': '桃園市', 'city': '桃園區', 'AreaEngName': 'Taoyuan Dist.' },
    { 'id': 333, 'county': '桃園市', 'city': '龜山區', 'AreaEngName': 'Guishan Dist.' },
    { 'id': 334, 'county': '桃園市', 'city': '八德區', 'AreaEngName': 'Bade Dist.' },
    { 'id': 335, 'county': '桃園市', 'city': '大溪區', 'AreaEngName': 'Daxi Dist.' },
    { 'id': 336, 'county': '桃園市', 'city': '復興區', 'AreaEngName': 'Fuxing Dist.' },
    { 'id': 337, 'county': '桃園市', 'city': '大園區', 'AreaEngName': 'Dayuan Dist.' },
    { 'id': 338, 'county': '桃園市', 'city': '蘆竹區', 'AreaEngName': 'Luzhu Dist.' },

    { 'id': 350, 'county': '苗栗縣', 'city': '竹南鎮', 'AreaEngName': 'Zhunan Township' },
    { 'id': 351, 'county': '苗栗縣', 'city': '頭份市', 'AreaEngName': 'Toufen City' },
    { 'id': 352, 'county': '苗栗縣', 'city': '三灣鄉', 'AreaEngName': 'Sanwan Township' },
    { 'id': 353, 'county': '苗栗縣', 'city': '南庄鄉', 'AreaEngName': 'Nanzhuang Township' },
    { 'id': 354, 'county': '苗栗縣', 'city': '獅潭鄉', 'AreaEngName': 'Shitan Township' },
    { 'id': 356, 'county': '苗栗縣', 'city': '後龍鎮', 'AreaEngName': 'Houlong Township' },
    { 'id': 357, 'county': '苗栗縣', 'city': '通霄鎮', 'AreaEngName': 'Tongxiao Township' },
    { 'id': 358, 'county': '苗栗縣', 'city': '苑裡鎮', 'AreaEngName': 'Yuanli Township' },
    { 'id': 360, 'county': '苗栗縣', 'city': '苗栗市', 'AreaEngName': 'Miaoli City' },
    { 'id': 361, 'county': '苗栗縣', 'city': '造橋鄉', 'AreaEngName': 'Zaoqiao Township' },
    { 'id': 362, 'county': '苗栗縣', 'city': '頭屋鄉', 'AreaEngName': 'Touwu Township' },
    { 'id': 363, 'county': '苗栗縣', 'city': '公館鄉', 'AreaEngName': 'Gongguan Township' },
    { 'id': 364, 'county': '苗栗縣', 'city': '大湖鄉', 'AreaEngName': 'Dahu Township' },
    { 'id': 365, 'county': '苗栗縣', 'city': '泰安鄉', 'AreaEngName': 'Tai’an Township' },
    { 'id': 366, 'county': '苗栗縣', 'city': '銅鑼鄉', 'AreaEngName': 'Tongluo Township' },
    { 'id': 367, 'county': '苗栗縣', 'city': '三義鄉', 'AreaEngName': 'Sanyi Township' },
    { 'id': 368, 'county': '苗栗縣', 'city': '西湖鄉', 'AreaEngName': 'Xihu Township' },
    { 'id': 369, 'county': '苗栗縣', 'city': '卓蘭鎮', 'AreaEngName': 'Zhuolan Township' },

    { 'id': 400, 'county': '臺中市', 'city': '中區', 'AreaEngName': 'Central Dist.' },
    { 'id': 401, 'county': '臺中市', 'city': '東區', 'AreaEngName': 'East Dist.' },
    { 'id': 402, 'county': '臺中市', 'city': '南區', 'AreaEngName': 'South Dist.' },
    { 'id': 403, 'county': '臺中市', 'city': '西區', 'AreaEngName': 'West Dist.' },
    { 'id': 404, 'county': '臺中市', 'city': '北區', 'AreaEngName': 'North Dist.' },
    { 'id': 406, 'county': '臺中市', 'city': '北屯區', 'AreaEngName': 'Beitun Dist.' },
    { 'id': 407, 'county': '臺中市', 'city': '西屯區', 'AreaEngName': 'Xitun Dist.' },
    { 'id': 408, 'county': '臺中市', 'city': '南屯區', 'AreaEngName': 'Nantun Dist.' },
    { 'id': 411, 'county': '臺中市', 'city': '太平區', 'AreaEngName': 'Taiping Dist.' },
    { 'id': 412, 'county': '臺中市', 'city': '大里區', 'AreaEngName': 'Dali Dist.' },
    { 'id': 413, 'county': '臺中市', 'city': '霧峰區', 'AreaEngName': 'Wufeng Dist.' },
    { 'id': 414, 'county': '臺中市', 'city': '烏日區', 'AreaEngName': 'Wuri Dist.' },
    { 'id': 420, 'county': '臺中市', 'city': '豐原區', 'AreaEngName': 'Fengyuan Dist.' },
    { 'id': 421, 'county': '臺中市', 'city': '后里區', 'AreaEngName': 'Houli Dist.' },
    { 'id': 422, 'county': '臺中市', 'city': '石岡區', 'AreaEngName': 'Shigang Dist.' },
    { 'id': 423, 'county': '臺中市', 'city': '東勢區', 'AreaEngName': 'Dongshi Dist.' },
    { 'id': 424, 'county': '臺中市', 'city': '和平區', 'AreaEngName': 'Heping Dist.' },
    { 'id': 426, 'county': '臺中市', 'city': '新社區', 'AreaEngName': 'Xinshe Dist.' },
    { 'id': 427, 'county': '臺中市', 'city': '潭子區', 'AreaEngName': 'Tanzi Dist.' },
    { 'id': 428, 'county': '臺中市', 'city': '大雅區', 'AreaEngName': 'Daya Dist.' },
    { 'id': 429, 'county': '臺中市', 'city': '神岡區', 'AreaEngName': 'Shengang Dist.' },
    { 'id': 432, 'county': '臺中市', 'city': '大肚區', 'AreaEngName': 'Dadu Dist.' },
    { 'id': 433, 'county': '臺中市', 'city': '沙鹿區', 'AreaEngName': 'Shalu Dist.' },
    { 'id': 434, 'county': '臺中市', 'city': '龍井區', 'AreaEngName': 'Longjing Dist.' },
    { 'id': 435, 'county': '臺中市', 'city': '梧棲區', 'AreaEngName': 'Wuqi Dist.' },
    { 'id': 436, 'county': '臺中市', 'city': '清水區', 'AreaEngName': 'Qingshui Dist.' },
    { 'id': 437, 'county': '臺中市', 'city': '大甲區', 'AreaEngName': 'Dajia Dist.' },
    { 'id': 438, 'county': '臺中市', 'city': '外埔區', 'AreaEngName': 'Waipu Dist.' },
    { 'id': 439, 'county': '臺中市', 'city': '大安區', 'AreaEngName': 'Da’an Dist.' },

    { 'id': 500, 'county': '彰化縣', 'city': '彰化市', 'AreaEngName': 'Changhua City' },
    { 'id': 502, 'county': '彰化縣', 'city': '芬園鄉', 'AreaEngName': 'Fenyuan Township' },
    { 'id': 503, 'county': '彰化縣', 'city': '花壇鄉', 'AreaEngName': 'Huatan Township' },
    { 'id': 504, 'county': '彰化縣', 'city': '秀水鄉', 'AreaEngName': 'Xiushui Township' },
    { 'id': 505, 'county': '彰化縣', 'city': '鹿港鎮', 'AreaEngName': 'Lukang Township' },
    { 'id': 506, 'county': '彰化縣', 'city': '福興鄉', 'AreaEngName': 'Fuxing Township' },
    { 'id': 507, 'county': '彰化縣', 'city': '線西鄉', 'AreaEngName': 'Xianxi Township' },
    { 'id': 508, 'county': '彰化縣', 'city': '和美鎮', 'AreaEngName': 'Hemei Township' },
    { 'id': 509, 'county': '彰化縣', 'city': '伸港鄉', 'AreaEngName': 'Shengang Township' },
    { 'id': 510, 'county': '彰化縣', 'city': '員林市', 'AreaEngName': 'Yuanlin City' },
    { 'id': 511, 'county': '彰化縣', 'city': '社頭鄉', 'AreaEngName': 'Shetou Township' },
    { 'id': 512, 'county': '彰化縣', 'city': '永靖鄉', 'AreaEngName': 'Yongjing Township' },
    { 'id': 513, 'county': '彰化縣', 'city': '埔心鄉', 'AreaEngName': 'Puxin Township' },
    { 'id': 514, 'county': '彰化縣', 'city': '溪湖鎮', 'AreaEngName': 'Xihu Township' },
    { 'id': 515, 'county': '彰化縣', 'city': '大村鄉', 'AreaEngName': 'Dacun Township' },
    { 'id': 516, 'county': '彰化縣', 'city': '埔鹽鄉', 'AreaEngName': 'Puyan Township' },
    { 'id': 520, 'county': '彰化縣', 'city': '田中鎮', 'AreaEngName': 'Tianzhong Township' },
    { 'id': 521, 'county': '彰化縣', 'city': '北斗鎮', 'AreaEngName': 'Beidou Township' },
    { 'id': 522, 'county': '彰化縣', 'city': '田尾鄉', 'AreaEngName': 'Tianwei Township' },
    { 'id': 523, 'county': '彰化縣', 'city': '埤頭鄉', 'AreaEngName': 'Pitou Township' },
    { 'id': 524, 'county': '彰化縣', 'city': '溪州鄉', 'AreaEngName': 'Xizhou Township' },
    { 'id': 525, 'county': '彰化縣', 'city': '竹塘鄉', 'AreaEngName': 'Zhutang Township' },
    { 'id': 526, 'county': '彰化縣', 'city': '二林鎮', 'AreaEngName': 'Erlin Township' },
    { 'id': 527, 'county': '彰化縣', 'city': '大城鄉', 'AreaEngName': 'Dacheng Township' },
    { 'id': 528, 'county': '彰化縣', 'city': '芳苑鄉', 'AreaEngName': 'Fangyuan Township' },
    { 'id': 530, 'county': '彰化縣', 'city': '二水鄉', 'AreaEngName': 'Ershui Township' },

    { 'id': 540, 'county': '南投縣', 'city': '南投市', 'AreaEngName': 'Nantou City' },
    { 'id': 541, 'county': '南投縣', 'city': '中寮鄉', 'AreaEngName': 'Zhongliao Township' },
    { 'id': 542, 'county': '南投縣', 'city': '草屯鎮', 'AreaEngName': 'Caotun Township' },
    { 'id': 544, 'county': '南投縣', 'city': '國姓鄉', 'AreaEngName': 'Guoxing Township' },
    { 'id': 545, 'county': '南投縣', 'city': '埔里鎮', 'AreaEngName': 'Puli Township' },
    { 'id': 546, 'county': '南投縣', 'city': '仁愛鄉', 'AreaEngName': 'Ren’ai Township' },
    { 'id': 551, 'county': '南投縣', 'city': '名間鄉', 'AreaEngName': 'Mingjian Township' },
    { 'id': 552, 'county': '南投縣', 'city': '集集鎮', 'AreaEngName': 'Jiji Township' },
    { 'id': 553, 'county': '南投縣', 'city': '水里鄉', 'AreaEngName': 'Shuili Township' },
    { 'id': 555, 'county': '南投縣', 'city': '魚池鄉', 'AreaEngName': 'Yuchi Township' },
    { 'id': 556, 'county': '南投縣', 'city': '信義鄉', 'AreaEngName': 'Xinyi Township' },
    { 'id': 557, 'county': '南投縣', 'city': '竹山鎮', 'AreaEngName': 'Zhushan Township' },
    { 'id': 558, 'county': '南投縣', 'city': '鹿谷鄉', 'AreaEngName': 'Lugu Township' },

    { 'id': 600, 'county': '嘉義市', 'city': '西區', 'AreaEngName': 'West Dist.' },
    { 'id': 600, 'county': '嘉義市', 'city': '東區', 'AreaEngName': 'East Dist.' },

    { 'id': 602, 'county': '嘉義縣', 'city': '番路鄉', 'AreaEngName': 'Fanlu Township' },
    { 'id': 603, 'county': '嘉義縣', 'city': '梅山鄉', 'AreaEngName': 'Meishan Township' },
    { 'id': 604, 'county': '嘉義縣', 'city': '竹崎鄉', 'AreaEngName': 'Zhuqi Township' },
    { 'id': 605, 'county': '嘉義縣', 'city': '阿里山鄉', 'AreaEngName': 'Alishan Township' },
    { 'id': 606, 'county': '嘉義縣', 'city': '中埔鄉', 'AreaEngName': 'Zhongpu Township' },
    { 'id': 607, 'county': '嘉義縣', 'city': '大埔鄉', 'AreaEngName': 'Dapu Township' },
    { 'id': 608, 'county': '嘉義縣', 'city': '水上鄉', 'AreaEngName': 'Shuishang Township' },
    { 'id': 611, 'county': '嘉義縣', 'city': '鹿草鄉', 'AreaEngName': 'Lucao Township' },
    { 'id': 612, 'county': '嘉義縣', 'city': '太保市', 'AreaEngName': 'Taibao City' },
    { 'id': 613, 'county': '嘉義縣', 'city': '朴子市', 'AreaEngName': 'Puzi City' },
    { 'id': 614, 'county': '嘉義縣', 'city': '東石鄉', 'AreaEngName': 'Dongshi Township' },
    { 'id': 615, 'county': '嘉義縣', 'city': '六腳鄉', 'AreaEngName': 'Liujiao Township' },
    { 'id': 616, 'county': '嘉義縣', 'city': '新港鄉', 'AreaEngName': 'Xingang Township' },
    { 'id': 621, 'county': '嘉義縣', 'city': '民雄鄉', 'AreaEngName': 'Minxiong Township' },
    { 'id': 622, 'county': '嘉義縣', 'city': '大林鎮', 'AreaEngName': 'Dalin Township' },
    { 'id': 623, 'county': '嘉義縣', 'city': '溪口鄉', 'AreaEngName': 'Xikou Township' },
    { 'id': 624, 'county': '嘉義縣', 'city': '義竹鄉', 'AreaEngName': 'Yizhu Township' },
    { 'id': 625, 'county': '嘉義縣', 'city': '布袋鎮', 'AreaEngName': 'Budai Township' },

    { 'id': 630, 'county': '雲林縣', 'city': '斗南鎮', 'AreaEngName': 'Dounan Township' },
    { 'id': 631, 'county': '雲林縣', 'city': '大埤鄉', 'AreaEngName': 'Dapi Township' },
    { 'id': 632, 'county': '雲林縣', 'city': '虎尾鎮', 'AreaEngName': 'Huwei Township' },
    { 'id': 633, 'county': '雲林縣', 'city': '土庫鎮', 'AreaEngName': 'Tuku Township' },
    { 'id': 634, 'county': '雲林縣', 'city': '褒忠鄉', 'AreaEngName': 'Baozhong Township' },
    { 'id': 635, 'county': '雲林縣', 'city': '東勢鄉', 'AreaEngName': 'Dongshi Township' },
    { 'id': 636, 'county': '雲林縣', 'city': '臺西鄉', 'AreaEngName': 'Taixi Township' },
    { 'id': 637, 'county': '雲林縣', 'city': '崙背鄉', 'AreaEngName': 'Lunbei Township' },
    { 'id': 638, 'county': '雲林縣', 'city': '麥寮鄉', 'AreaEngName': 'Mailiao Township' },
    { 'id': 640, 'county': '雲林縣', 'city': '斗六市', 'AreaEngName': 'Douliu City' },
    { 'id': 643, 'county': '雲林縣', 'city': '林內鄉', 'AreaEngName': 'Linnei Township' },
    { 'id': 646, 'county': '雲林縣', 'city': '古坑鄉', 'AreaEngName': 'Gukeng Township' },
    { 'id': 647, 'county': '雲林縣', 'city': '莿桐鄉', 'AreaEngName': 'Citong Township' },
    { 'id': 648, 'county': '雲林縣', 'city': '西螺鎮', 'AreaEngName': 'Xiluo Township' },
    { 'id': 649, 'county': '雲林縣', 'city': '二崙鄉', 'AreaEngName': 'Erlun Township' },
    { 'id': 651, 'county': '雲林縣', 'city': '北港鎮', 'AreaEngName': 'Beigang Township' },
    { 'id': 652, 'county': '雲林縣', 'city': '水林鄉', 'AreaEngName': 'Shuilin Township' },
    { 'id': 653, 'county': '雲林縣', 'city': '口湖鄉', 'AreaEngName': 'Kouhu Township' },
    { 'id': 654, 'county': '雲林縣', 'city': '四湖鄉', 'AreaEngName': 'Sihu Township' },
    { 'id': 655, 'county': '雲林縣', 'city': '元長鄉', 'AreaEngName': 'Yuanchang Township' },

    { 'id': 700, 'county': '臺南市', 'city': '中西區', 'AreaEngName': 'West Central Dist.' },
    { 'id': 701, 'county': '臺南市', 'city': '東區', 'AreaEngName': 'East Dist.' },
    { 'id': 702, 'county': '臺南市', 'city': '南區', 'AreaEngName': 'South Dist.' },
    { 'id': 704, 'county': '臺南市', 'city': '北區', 'AreaEngName': 'North Dist.' },
    { 'id': 708, 'county': '臺南市', 'city': '安平區', 'AreaEngName': 'Anping Dist.' },
    { 'id': 709, 'county': '臺南市', 'city': '安南區', 'AreaEngName': 'Annan Dist.' },
    { 'id': 710, 'county': '臺南市', 'city': '永康區', 'AreaEngName': 'Yongkang Dist.' },
    { 'id': 711, 'county': '臺南市', 'city': '歸仁區', 'AreaEngName': 'Guiren Dist.' },
    { 'id': 712, 'county': '臺南市', 'city': '新化區', 'AreaEngName': 'Xinhua Dist.' },
    { 'id': 713, 'county': '臺南市', 'city': '左鎮區', 'AreaEngName': 'Zuozhen Dist.' },
    { 'id': 714, 'county': '臺南市', 'city': '玉井區', 'AreaEngName': 'Yujing Dist.' },
    { 'id': 715, 'county': '臺南市', 'city': '楠西區', 'AreaEngName': 'Nanxi Dist.' },
    { 'id': 716, 'county': '臺南市', 'city': '南化區', 'AreaEngName': 'Nanhua Dist.' },
    { 'id': 717, 'county': '臺南市', 'city': '仁德區', 'AreaEngName': 'Rende Dist.' },
    { 'id': 718, 'county': '臺南市', 'city': '關廟區', 'AreaEngName': 'Guanmiao Dist.' },
    { 'id': 719, 'county': '臺南市', 'city': '龍崎區', 'AreaEngName': 'Longqi Dist.' },
    { 'id': 720, 'county': '臺南市', 'city': '官田區', 'AreaEngName': 'Guantian Dist.' },
    { 'id': 721, 'county': '臺南市', 'city': '麻豆區', 'AreaEngName': 'Madou Dist.' },
    { 'id': 722, 'county': '臺南市', 'city': '佳里區', 'AreaEngName': 'Jiali Dist.' },
    { 'id': 723, 'county': '臺南市', 'city': '西港區', 'AreaEngName': 'Xigang Dist.' },
    { 'id': 724, 'county': '臺南市', 'city': '七股區', 'AreaEngName': 'Qigu Dist.' },
    { 'id': 725, 'county': '臺南市', 'city': '將軍區', 'AreaEngName': 'Jiangjun Dist.' },
    { 'id': 726, 'county': '臺南市', 'city': '學甲區', 'AreaEngName': 'Xuejia Dist.' },
    { 'id': 727, 'county': '臺南市', 'city': '北門區', 'AreaEngName': 'Beimen Dist.' },
    { 'id': 730, 'county': '臺南市', 'city': '新營區', 'AreaEngName': 'Xinying Dist.' },
    { 'id': 731, 'county': '臺南市', 'city': '後壁區', 'AreaEngName': 'Houbi Dist.' },
    { 'id': 732, 'county': '臺南市', 'city': '白河區', 'AreaEngName': 'Baihe Dist.' },
    { 'id': 733, 'county': '臺南市', 'city': '東山區', 'AreaEngName': 'Dongshan Dist.' },
    { 'id': 734, 'county': '臺南市', 'city': '六甲區', 'AreaEngName': 'Liujia Dist.' },
    { 'id': 735, 'county': '臺南市', 'city': '下營區', 'AreaEngName': 'Xiaying Dist.' },
    { 'id': 736, 'county': '臺南市', 'city': '柳營區', 'AreaEngName': 'Liuying Dist.' },
    { 'id': 737, 'county': '臺南市', 'city': '鹽水區', 'AreaEngName': 'Yanshui Dist.' },
    { 'id': 741, 'county': '臺南市', 'city': '善化區', 'AreaEngName': 'Shanhua Dist.' },
    { 'id': 744, 'county': '臺南市', 'city': '新市區', 'AreaEngName': 'Xinshi Dist.' },
    { 'id': 742, 'county': '臺南市', 'city': '大內區', 'AreaEngName': 'Danei Dist.' },
    { 'id': 743, 'county': '臺南市', 'city': '山上區', 'AreaEngName': 'Shanshang Dist.' },
    { 'id': 745, 'county': '臺南市', 'city': '安定區', 'AreaEngName': 'Anding Dist.' },

    { 'id': 800, 'county': '高雄市', 'city': '新興區', 'AreaEngName': 'Xinxing Dist.' },
    { 'id': 801, 'county': '高雄市', 'city': '前金區', 'AreaEngName': 'Qianjin Dist.' },
    { 'id': 802, 'county': '高雄市', 'city': '苓雅區', 'AreaEngName': 'Lingya Dist.' },
    { 'id': 803, 'county': '高雄市', 'city': '鹽埕區', 'AreaEngName': 'Yancheng Dist.' },
    { 'id': 804, 'county': '高雄市', 'city': '鼓山區', 'AreaEngName': 'Gushan Dist.' },
    { 'id': 805, 'county': '高雄市', 'city': '旗津區', 'AreaEngName': 'Qijin Dist.' },
    { 'id': 806, 'county': '高雄市', 'city': '前鎮區', 'AreaEngName': 'Qianzhen Dist.' },
    { 'id': 807, 'county': '高雄市', 'city': '三民區', 'AreaEngName': 'Sanmin Dist.' },
    { 'id': 811, 'county': '高雄市', 'city': '楠梓區', 'AreaEngName': 'Nanzi Dist.' },
    { 'id': 812, 'county': '高雄市', 'city': '小港區', 'AreaEngName': 'Xiaogang Dist.' },
    { 'id': 813, 'county': '高雄市', 'city': '左營區', 'AreaEngName': 'Zuoying Dist.' },
    { 'id': 814, 'county': '高雄市', 'city': '仁武區', 'AreaEngName': 'Renwu Dist.' },
    { 'id': 815, 'county': '高雄市', 'city': '大社區', 'AreaEngName': 'Dashe Dist.' },
    { 'id': 820, 'county': '高雄市', 'city': '岡山區', 'AreaEngName': 'Gangshan Dist.' },
    { 'id': 821, 'county': '高雄市', 'city': '路竹區', 'AreaEngName': 'Luzhu Dist.' },
    { 'id': 822, 'county': '高雄市', 'city': '阿蓮區', 'AreaEngName': 'Alian Dist.' },
    { 'id': 823, 'county': '高雄市', 'city': '田寮區', 'AreaEngName': 'Tianliao Dist.' },
    { 'id': 824, 'county': '高雄市', 'city': '燕巢區', 'AreaEngName': 'Yanchao Dist.' },
    { 'id': 825, 'county': '高雄市', 'city': '橋頭區', 'AreaEngName': 'Qiaotou Dist.' },
    { 'id': 826, 'county': '高雄市', 'city': '梓官區', 'AreaEngName': 'Ziguan Dist.' },
    { 'id': 827, 'county': '高雄市', 'city': '彌陀區', 'AreaEngName': 'Mituo Dist.' },
    { 'id': 828, 'county': '高雄市', 'city': '永安區', 'AreaEngName': 'Yong’an Dist.' },
    { 'id': 829, 'county': '高雄市', 'city': '湖內區', 'AreaEngName': 'Hunei Dist.' },
    { 'id': 830, 'county': '高雄市', 'city': '鳳山區', 'AreaEngName': 'Fengshan Dist.' },
    { 'id': 831, 'county': '高雄市', 'city': '大寮區', 'AreaEngName': 'Daliao Dist.' },
    { 'id': 832, 'county': '高雄市', 'city': '林園區', 'AreaEngName': 'Linyuan Dist.' },
    { 'id': 833, 'county': '高雄市', 'city': '鳥松區', 'AreaEngName': 'Niaosong Dist.' },
    { 'id': 840, 'county': '高雄市', 'city': '大樹區', 'AreaEngName': 'Dashu Dist.' },
    { 'id': 842, 'county': '高雄市', 'city': '旗山區', 'AreaEngName': 'Qishan Dist.' },
    { 'id': 843, 'county': '高雄市', 'city': '美濃區', 'AreaEngName': 'Meinong Dist.' },
    { 'id': 844, 'county': '高雄市', 'city': '六龜區', 'AreaEngName': 'Liugui Dist.' },
    { 'id': 845, 'county': '高雄市', 'city': '內門區', 'AreaEngName': 'Neimen Dist.' },
    { 'id': 846, 'county': '高雄市', 'city': '杉林區', 'AreaEngName': 'Shanlin Dist.' },
    { 'id': 847, 'county': '高雄市', 'city': '甲仙區', 'AreaEngName': 'Jiaxian Dist.' },
    { 'id': 848, 'county': '高雄市', 'city': '桃源區', 'AreaEngName': 'Taoyuan Dist.' },
    { 'id': 849, 'county': '高雄市', 'city': '那瑪夏區', 'AreaEngName': 'Namaxia Dist.' },
    { 'id': 851, 'county': '高雄市', 'city': '茂林區', 'AreaEngName': 'Maolin Dist.' },
    { 'id': 852, 'county': '高雄市', 'city': '茄萣區', 'AreaEngName': 'Qieding Dist.' },

    { 'id': 900, 'county': '屏東縣', 'city': '屏東市', 'AreaEngName': 'Pingtung City' },
    { 'id': 901, 'county': '屏東縣', 'city': '三地門鄉', 'AreaEngName': 'Sandimen Township' },
    { 'id': 902, 'county': '屏東縣', 'city': '霧臺鄉', 'AreaEngName': 'Wutai Township' },
    { 'id': 903, 'county': '屏東縣', 'city': '瑪家鄉', 'AreaEngName': 'Majia Township' },
    { 'id': 904, 'county': '屏東縣', 'city': '九如鄉', 'AreaEngName': 'Jiuru Township' },
    { 'id': 905, 'county': '屏東縣', 'city': '里港鄉', 'AreaEngName': 'Ligang Township' },
    { 'id': 906, 'county': '屏東縣', 'city': '高樹鄉', 'AreaEngName': 'Gaoshu Township' },
    { 'id': 907, 'county': '屏東縣', 'city': '鹽埔鄉', 'AreaEngName': 'Yanpu Township' },
    { 'id': 908, 'county': '屏東縣', 'city': '長治鄉', 'AreaEngName': 'Changzhi Township' },
    { 'id': 909, 'county': '屏東縣', 'city': '麟洛鄉', 'AreaEngName': 'Linluo Township' },
    { 'id': 911, 'county': '屏東縣', 'city': '竹田鄉', 'AreaEngName': 'Zhutian Township' },
    { 'id': 912, 'county': '屏東縣', 'city': '內埔鄉', 'AreaEngName': 'Neipu Township' },
    { 'id': 913, 'county': '屏東縣', 'city': '萬丹鄉', 'AreaEngName': 'Wandan Township' },
    { 'id': 920, 'county': '屏東縣', 'city': '潮州鎮', 'AreaEngName': 'Chaozhou Township' },
    { 'id': 921, 'county': '屏東縣', 'city': '泰武鄉', 'AreaEngName': 'Taiwu Township' },
    { 'id': 922, 'county': '屏東縣', 'city': '來義鄉', 'AreaEngName': 'Laiyi Township' },
    { 'id': 923, 'county': '屏東縣', 'city': '萬巒鄉', 'AreaEngName': 'Wanluan Township' },
    { 'id': 924, 'county': '屏東縣', 'city': '崁頂鄉', 'AreaEngName': 'Kanding Township' },
    { 'id': 925, 'county': '屏東縣', 'city': '新埤鄉', 'AreaEngName': 'Xinpi Township' },
    { 'id': 926, 'county': '屏東縣', 'city': '南州鄉', 'AreaEngName': 'Nanzhou Township' },
    { 'id': 927, 'county': '屏東縣', 'city': '林邊鄉', 'AreaEngName': 'Linbian Township' },
    { 'id': 928, 'county': '屏東縣', 'city': '東港鎮', 'AreaEngName': 'Donggang Township' },
    { 'id': 931, 'county': '屏東縣', 'city': '佳冬鄉', 'AreaEngName': 'Jiadong Township' },
    { 'id': 932, 'county': '屏東縣', 'city': '新園鄉', 'AreaEngName': 'Xinyuan Township' },
    { 'id': 940, 'county': '屏東縣', 'city': '枋寮鄉', 'AreaEngName': 'Fangliao Township' },
    { 'id': 941, 'county': '屏東縣', 'city': '枋山鄉', 'AreaEngName': 'Fangshan Township' },
    { 'id': 942, 'county': '屏東縣', 'city': '春日鄉', 'AreaEngName': 'Chunri Township' },
    { 'id': 943, 'county': '屏東縣', 'city': '獅子鄉', 'AreaEngName': 'Shizi Township' },
    { 'id': 944, 'county': '屏東縣', 'city': '車城鄉', 'AreaEngName': 'Checheng Township' },
    { 'id': 945, 'county': '屏東縣', 'city': '牡丹鄉', 'AreaEngName': 'Mudan Township' },
    { 'id': 946, 'county': '屏東縣', 'city': '恆春鎮', 'AreaEngName': 'Hengchun Township' },
    { 'id': 947, 'county': '屏東縣', 'city': '滿州鄉', 'AreaEngName': 'Manzhou Township' },

    { 'id': 950, 'county': '臺東縣', 'city': '臺東市', 'AreaEngName': 'Taitung City' },
    { 'id': 953, 'county': '臺東縣', 'city': '延平鄉', 'AreaEngName': 'Yanping Township' },
    { 'id': 954, 'county': '臺東縣', 'city': '卑南鄉', 'AreaEngName': 'Beinan Township' },
    { 'id': 955, 'county': '臺東縣', 'city': '鹿野鄉', 'AreaEngName': 'Luye Township' },
    { 'id': 956, 'county': '臺東縣', 'city': '關山鎮', 'AreaEngName': 'Guanshan Township' },
    { 'id': 957, 'county': '臺東縣', 'city': '海端鄉', 'AreaEngName': 'Haiduan Township' },
    { 'id': 958, 'county': '臺東縣', 'city': '池上鄉', 'AreaEngName': 'Chishang Township' },
    { 'id': 959, 'county': '臺東縣', 'city': '東河鄉', 'AreaEngName': 'Donghe Township' },
    { 'id': 961, 'county': '臺東縣', 'city': '成功鎮', 'AreaEngName': 'Chenggong Township' },
    { 'id': 962, 'county': '臺東縣', 'city': '長濱鄉', 'AreaEngName': 'Changbin Township' },
    { 'id': 963, 'county': '臺東縣', 'city': '太麻里鄉', 'AreaEngName': 'Taimali Township' },
    { 'id': 964, 'county': '臺東縣', 'city': '金峰鄉', 'AreaEngName': 'Jinfeng Township' },
    { 'id': 965, 'county': '臺東縣', 'city': '大武鄉', 'AreaEngName': 'Dawu Township' },
    { 'id': 966, 'county': '臺東縣', 'city': '達仁鄉', 'AreaEngName': 'Daren Township' },

    { 'id': 970, 'county': '花蓮縣', 'city': '花蓮市', 'AreaEngName': 'Hualien City' },
    { 'id': 971, 'county': '花蓮縣', 'city': '新城鄉', 'AreaEngName': 'Xincheng Township' },
    { 'id': 972, 'county': '花蓮縣', 'city': '秀林鄉', 'AreaEngName': 'Xiulin Township' },
    { 'id': 973, 'county': '花蓮縣', 'city': '吉安鄉', 'AreaEngName': 'Ji’an Township' },
    { 'id': 974, 'county': '花蓮縣', 'city': '壽豐鄉', 'AreaEngName': 'Shoufeng Township' },
    { 'id': 975, 'county': '花蓮縣', 'city': '鳳林鎮', 'AreaEngName': 'Fenglin Township' },
    { 'id': 976, 'county': '花蓮縣', 'city': '光復鄉', 'AreaEngName': 'Guangfu Township' },
    { 'id': 977, 'county': '花蓮縣', 'city': '豐濱鄉', 'AreaEngName': 'Fengbin Township' },
    { 'id': 978, 'county': '花蓮縣', 'city': '瑞穗鄉', 'AreaEngName': 'Ruisui Township' },
    { 'id': 979, 'county': '花蓮縣', 'city': '萬榮鄉', 'AreaEngName': 'Wanrong Township' },
    { 'id': 981, 'county': '花蓮縣', 'city': '玉里鎮', 'AreaEngName': 'Yuli Township' },
    { 'id': 982, 'county': '花蓮縣', 'city': '卓溪鄉', 'AreaEngName': 'Zhuoxi Township' },
    { 'id': 983, 'county': '花蓮縣', 'city': '富里鄉', 'AreaEngName': 'Fuli Township' }
]
