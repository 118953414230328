import twzipcode from '@/components/zipcode/twzipcode-data/src'
import { identity } from 'lodash'

export default {
  props: {
    valueLocale: {
      type: String,
      default: 'zh-tw'
    },
    textLocale: {
      type: String,
      default: 'zh-tw'
    }
  },
  methods: {
    getData ({ dataName, transform = identity }) {
      const ids = twzipcode()[dataName].map(data => data.id)
      const valueDict = twzipcode(this.valueLocale)[dataName] //keyBy(twzipcode(this.valueLocale)[dataName], 'id')
      const textDict = twzipcode(this.textLocale)[dataName] //keyBy(twzipcode(this.textLocale)[dataName], 'id')

      return this.toOptions(ids, valueDict, textDict)
                .map((option, index) => transform(option, valueDict, textDict, index))
    },

    toOptions (ids, valueDict, textDict) {
      return ids.map((id, index) => this.toOption(id, valueDict[index], textDict[index]))
    },

    toOption (id, valueObject, textObject) {
      return {
        id,
        value: this.renderOption(valueObject, this.valueTemplate),
        text: this.renderOption(textObject, this.textTemplate)
      }
    },

    renderOption (object, template) {
      return Object.keys(object).reduce((text, key) => {
        return text.replace(`:${key}`, object[key])
      }, template)
    }
  }
}