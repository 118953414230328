module.exports = [
  {
    id: '臺北市',
    name: '臺北市'
  },
  {
    id: '基隆市',
    name: '基隆市'
  },
  {
    id: '新北市',
    name: '新北市'
  },
  {
    id: '宜蘭縣',
    name: '宜蘭縣'
  },
  {
    id: '新竹市',
    name: '新竹市'
  },
  {
    id: '新竹縣',
    name: '新竹縣'
  },
  {
    id: '桃園市',
    name: '桃園市'
  },
  {
    id: '苗栗縣',
    name: '苗栗縣'
  },
  {
    id: '臺中市',
    name: '臺中市'
  },
  {
    id: '彰化縣',
    name: '彰化縣'
  },
  {
    id: '南投縣',
    name: '南投縣'
  },
  {
    id: '嘉義市',
    name: '嘉義市'
  },
  {
    id: '嘉義縣',
    name: '嘉義縣'
  },
  {
    id: '雲林縣',
    name: '雲林縣'
  },
  {
    id: '臺南市',
    name: '臺南市'
  },
  {
    id: '高雄市',
    name: '高雄市'
  },
  {
    id: '澎湖縣',
    name: '澎湖縣'
  },
  {
    id: '屏東縣',
    name: '屏東縣'
  },
  {
    id: '臺東縣',
    name: '臺東縣'
  },
  {
    id: '花蓮縣',
    name: '花蓮縣'
  },
  {
    id: '金門縣',
    name: '金門縣'
  },
  {
    id: '連江縣',
    name: '連江縣'
  }
]
