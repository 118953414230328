module.exports = [
    { 'id': 100, 'county': 'Taipei City', 'city': 'Zhongzheng District' },
    { 'id': 103, 'county': 'Taipei City', 'city': 'Daton District' },
    { 'id': 104, 'county': 'Taipei City', 'city': 'Zhongshan District' },
    { 'id': 105, 'county': 'Taipei City', 'city': 'Songshan District' },
    { 'id': 106, 'county': 'Taipei City', 'city': "Da'an District" },
    { 'id': 108, 'county': 'Taipei City', 'city': 'Wanhua Disrict' },
    { 'id': 110, 'county': 'Taipei City', 'city': 'Xinyi District' },
    { 'id': 111, 'county': 'Taipei City', 'city': 'Shilin District' },
    { 'id': 112, 'county': 'Taipei City', 'city': 'Beitou District' },
    { 'id': 114, 'county': 'Taipei City', 'city': 'Neihu District' },
    { 'id': 115, 'county': 'Taipei City', 'city': 'Nangang District' },
    { 'id': 116, 'county': 'Taipei City', 'city': 'Wenshan District' },
    { 'id': 200, 'county': 'Keelung City', 'city': "Ren'ai District" },
    { 'id': 201, 'county': 'Keelung City', 'city': 'Xinyi District' },
    { 'id': 202, 'county': 'Keelung City', 'city': 'Zhongzheng District' },
    { 'id': 203, 'county': 'Keelung City', 'city': 'Zhongshan District' },
    { 'id': 204, 'county': 'Keelung City', 'city': 'Anle District' },
    { 'id': 205, 'county': 'Keelung City', 'city': 'Nuannuan District' },
    { 'id': 206, 'county': 'Keelung City', 'city': 'Qidu District' },
    { 'id': 207, 'county': 'New Taipei City', 'city': 'Wanli District' },
    { 'id': 208, 'county': 'New Taipei City', 'city': 'Jinshan District' },
    { 'id': 220, 'county': 'New Taipei City', 'city': 'Banqiao District' },
    { 'id': 221, 'county': 'New Taipei City', 'city': 'Xizhi District' },
    { 'id': 222, 'county': 'New Taipei City', 'city': 'Shenkeng District' },
    { 'id': 223, 'county': 'New Taipei City', 'city': 'Shiding District' },
    { 'id': 224, 'county': 'New Taipei City', 'city': 'Ruifang District' },
    { 'id': 226, 'county': 'New Taipei City', 'city': 'Pingxi District' },
    { 'id': 227, 'county': 'New Taipei City', 'city': 'Shuangxi District' },
    { 'id': 228, 'county': 'New Taipei City', 'city': 'Gongliao District' },
    { 'id': 231, 'county': 'New Taipei City', 'city': 'Xindian Distict' },
    { 'id': 232, 'county': 'New Taipei City', 'city': 'Pinglin District' },
    { 'id': 233, 'county': 'New Taipei City', 'city': 'Wulai District' },
    { 'id': 234, 'county': 'New Taipei City', 'city': 'Yonghe District' },
    { 'id': 235, 'county': 'New Taipei City', 'city': 'Zhonghe District' },
    { 'id': 236, 'county': 'New Taipei City', 'city': 'Tucheng District' },
    { 'id': 237, 'county': 'New Taipei City', 'city': 'Sanxia District' },
    { 'id': 238, 'county': 'New Taipei City', 'city': 'Shulin District' },
    { 'id': 239, 'county': 'New Taipei City', 'city': 'Yingge District' },
    { 'id': 241, 'county': 'New Taipei City', 'city': 'Sanchong District' },
    { 'id': 242, 'county': 'New Taipei City', 'city': 'Xinzhuang District' },
    { 'id': 243, 'county': 'New Taipei City', 'city': 'Taishan District' },
    { 'id': 244, 'county': 'New Taipei City', 'city': 'Linkou District' },
    { 'id': 247, 'county': 'New Taipei City', 'city': 'Luzhou District' },
    { 'id': 248, 'county': 'New Taipei City', 'city': 'Wugu District' },
    { 'id': 249, 'county': 'New Taipei City', 'city': 'Bali District' },
    { 'id': 251, 'county': 'New Taipei City', 'city': 'Tamsui District' },
    { 'id': 252, 'county': 'New Taipei City', 'city': 'Sanzhi District' },
    { 'id': 253, 'county': 'New Taipei City', 'city': 'Shimen District' },
    { 'id': 260, 'county': 'Yilan County', 'city': 'Yilan City' },
    { 'id': 261, 'county': 'Yilan County', 'city': 'Toucheng Township' },
    { 'id': 262, 'county': 'Yilan County', 'city': 'Jiaoxi Township' },
    { 'id': 263, 'county': 'Yilan County', 'city': 'Zhuangwei Township' },
    { 'id': 264, 'county': 'Yilan County', 'city': 'Yuanshan Township' },
    { 'id': 265, 'county': 'Yilan County', 'city': 'Luodong Township' },
    { 'id': 266, 'county': 'Yilan County', 'city': 'Sanxing Township' },
    { 'id': 267, 'county': 'Yilan County', 'city': 'Datong Township' },
    { 'id': 268, 'county': 'Yilan County', 'city': 'Wujie Township' },
    { 'id': 269, 'county': 'Yilan County', 'city': 'Dongshan Township' },
    { 'id': 270, 'county': 'Yilan County', 'city': "Su'ao Township" },
    { 'id': 272, 'county': 'Yilan County', 'city': "Nan'ao Township" },
    { 'id': 290, 'county': 'Yilan County', 'city': 'Diaoyu Islands' },
    { 'id': 300, 'county': 'Hsinchu City', 'city': 'East District' },
    { 'id': 300, 'county': 'Hsinchu City', 'city': 'North District' },
    { 'id': 300, 'county': 'Hsinchu City', 'city': 'Xiangshan District' },
    { 'id': 302, 'county': 'HsinChu County', 'city': 'Zhubei City' },
    { 'id': 303, 'county': 'HsinChu County', 'city': 'Hukou Township' },
    { 'id': 304, 'county': 'HsinChu County', 'city': 'Xinfeng Township' },
    { 'id': 305, 'county': 'HsinChu County', 'city': 'Xinpu Township' },
    { 'id': 306, 'county': 'HsinChu County', 'city': 'Guanxi Township' },
    { 'id': 307, 'county': 'HsinChu County', 'city': 'Qionglin Township' },
    { 'id': 308, 'county': 'HsinChu County', 'city': 'Baoshan Township' },
    { 'id': 310, 'county': 'HsinChu County', 'city': 'Zhudong Township' },
    { 'id': 311, 'county': 'HsinChu County', 'city': 'Wufeng Township' },
    { 'id': 312, 'county': 'HsinChu County', 'city': 'Hengshan Township' },
    { 'id': 313, 'county': 'HsinChu County', 'city': 'Jianshi Township' },
    { 'id': 314, 'county': 'HsinChu County', 'city': 'Beipu Township' },
    { 'id': 315, 'county': 'HsinChu County', 'city': 'Emei Township' },
    { 'id': 320, 'county': 'Taoyuan City', 'city': 'Zhongli District' },
    { 'id': 324, 'county': 'Taoyuan City', 'city': 'Pingzhen District' },
    { 'id': 325, 'county': 'Taoyuan City', 'city': 'Longtan District' },
    { 'id': 326, 'county': 'Taoyuan City', 'city': 'Yangmei District' },
    { 'id': 327, 'county': 'Taoyuan City', 'city': 'Xinwu District' },
    { 'id': 328, 'county': 'Taoyuan City', 'city': 'Guanyin District' },
    { 'id': 330, 'county': 'Taoyuan City', 'city': 'Taoyuan District' },
    { 'id': 333, 'county': 'Taoyuan City', 'city': 'Guishan District' },
    { 'id': 334, 'county': 'Taoyuan City', 'city': 'Bade District' },
    { 'id': 335, 'county': 'Taoyuan City', 'city': 'Daxi District' },
    { 'id': 336, 'county': 'Taoyuan City', 'city': 'Fuxing District' },
    { 'id': 337, 'county': 'Taoyuan City', 'city': 'Dayuan District' },
    { 'id': 338, 'county': 'Taoyuan City', 'city': 'Luzhu District' },
    { 'id': 350, 'county': 'Miaoli County', 'city': 'Zhunan Township' },
    { 'id': 351, 'county': 'Miaoli County', 'city': 'Toufen Town' },
    { 'id': 352, 'county': 'Miaoli County', 'city': 'Sanwan Township' },
    { 'id': 353, 'county': 'Miaoli County', 'city': 'Nanzhuang Township' },
    { 'id': 354, 'county': 'Miaoli County', 'city': 'Shitan Township' },
    { 'id': 356, 'county': 'Miaoli County', 'city': 'Houlong Township' },
    { 'id': 357, 'county': 'Miaoli County', 'city': 'Tongxiao Township' },
    { 'id': 358, 'county': 'Miaoli County', 'city': 'Yuanli Township' },
    { 'id': 360, 'county': 'Miaoli County', 'city': 'Miaoli City' },
    { 'id': 361, 'county': 'Miaoli County', 'city': 'Zaoqiao Township' },
    { 'id': 362, 'county': 'Miaoli County', 'city': 'Touwu Township' },
    { 'id': 363, 'county': 'Miaoli County', 'city': 'Gongguan Township' },
    { 'id': 364, 'county': 'Miaoli County', 'city': 'Dahu Township' },
    { 'id': 365, 'county': 'Miaoli County', 'city': "Tai'an Township" },
    { 'id': 366, 'county': 'Miaoli County', 'city': 'Tongluo Township' },
    { 'id': 367, 'county': 'Miaoli County', 'city': 'Sanyi Township' },
    { 'id': 368, 'county': 'Miaoli County', 'city': 'Xihu Township' },
    { 'id': 369, 'county': 'Miaoli County', 'city': 'Zhuolan Township' },
    { 'id': 400, 'county': 'Taichung City', 'city': 'Central District' },
    { 'id': 401, 'county': 'Taichung City', 'city': 'East District' },
    { 'id': 402, 'county': 'Taichung City', 'city': 'South District' },
    { 'id': 403, 'county': 'Taichung City', 'city': 'West District' },
    { 'id': 404, 'county': 'Taichung City', 'city': 'North District' },
    { 'id': 406, 'county': 'Taichung City', 'city': 'Beitun District' },
    { 'id': 407, 'county': 'Taichung City', 'city': 'Xitun District' },
    { 'id': 408, 'county': 'Taichung City', 'city': 'Nantun District' },
    { 'id': 411, 'county': 'Taichung City', 'city': 'Taiping District' },
    { 'id': 412, 'county': 'Taichung City', 'city': 'Dali District' },
    { 'id': 413, 'county': 'Taichung City', 'city': 'Wufeng District' },
    { 'id': 414, 'county': 'Taichung City', 'city': 'Wuri District' },
    { 'id': 420, 'county': 'Taichung City', 'city': 'Fengyuan District' },
    { 'id': 421, 'county': 'Taichung City', 'city': 'Houli District' },
    { 'id': 422, 'county': 'Taichung City', 'city': 'Shigang District' },
    { 'id': 423, 'county': 'Taichung City', 'city': 'Dongshi District' },
    { 'id': 424, 'county': 'Taichung City', 'city': 'Heping District' },
    { 'id': 426, 'county': 'Taichung City', 'city': 'Xinshe District' },
    { 'id': 427, 'county': 'Taichung City', 'city': 'Tanzi District' },
    { 'id': 428, 'county': 'Taichung City', 'city': 'Daya District' },
    { 'id': 429, 'county': 'Taichung City', 'city': 'Shengang District' },
    { 'id': 432, 'county': 'Taichung City', 'city': 'Dadu District' },
    { 'id': 433, 'county': 'Taichung City', 'city': 'Shalu District' },
    { 'id': 434, 'county': 'Taichung City', 'city': 'Longjing District' },
    { 'id': 435, 'county': 'Taichung City', 'city': 'Wuqi District' },
    { 'id': 436, 'county': 'Taichung City', 'city': 'Qingshui District' },
    { 'id': 437, 'county': 'Taichung City', 'city': 'Dajia District' },
    { 'id': 438, 'county': 'Taichung City', 'city': 'Waipu District' },
    { 'id': 439, 'county': 'Taichung City', 'city': "Da'an District" },
    { 'id': 500, 'county': 'Changhua County', 'city': 'Changhua City' },
    { 'id': 502, 'county': 'Changhua County', 'city': 'Fenyuan Township' },
    { 'id': 503, 'county': 'Changhua County', 'city': 'Huatan Township' },
    { 'id': 504, 'county': 'Changhua County', 'city': 'Xiushui Township' },
    { 'id': 505, 'county': 'Changhua County', 'city': 'Lukang Township' },
    { 'id': 506, 'county': 'Changhua County', 'city': 'Fuxing Township' },
    { 'id': 507, 'county': 'Changhua County', 'city': 'Xianxi Township' },
    { 'id': 508, 'county': 'Changhua County', 'city': 'Hemei Township' },
    { 'id': 509, 'county': 'Changhua County', 'city': 'Shengang Township' },
    { 'id': 510, 'county': 'Changhua County', 'city': 'Yuanlin市' },
    { 'id': 511, 'county': 'Changhua County', 'city': 'Shetou Township' },
    { 'id': 512, 'county': 'Changhua County', 'city': 'Yongjing Township' },
    { 'id': 513, 'county': 'Changhua County', 'city': 'Puxin Township' },
    { 'id': 514, 'county': 'Changhua County', 'city': 'Xihu Township' },
    { 'id': 515, 'county': 'Changhua County', 'city': 'Dacun Township' },
    { 'id': 516, 'county': 'Changhua County', 'city': 'Puyan Township' },
    { 'id': 520, 'county': 'Changhua County', 'city': 'Tianzhong Township' },
    { 'id': 521, 'county': 'Changhua County', 'city': 'Beidu Township' },
    { 'id': 522, 'county': 'Changhua County', 'city': 'Tianwei Township' },
    { 'id': 523, 'county': 'Changhua County', 'city': 'Pitou Township' },
    { 'id': 524, 'county': 'Changhua County', 'city': 'Xizhou Township' },
    { 'id': 525, 'county': 'Changhua County', 'city': 'Zhutang Township' },
    { 'id': 526, 'county': 'Changhua County', 'city': 'Erlin Township' },
    { 'id': 527, 'county': 'Changhua County', 'city': 'Dacheng Township' },
    { 'id': 528, 'county': 'Changhua County', 'city': 'Fangyuan Township' },
    { 'id': 530, 'county': 'Changhua County', 'city': 'Ershui Township' },
    { 'id': 540, 'county': 'Nantou County', 'city': 'Nantou City' },
    { 'id': 541, 'county': 'Nantou County', 'city': 'Zhongliao Township' },
    { 'id': 542, 'county': 'Nantou County', 'city': 'Caotun Township' },
    { 'id': 544, 'county': 'Nantou County', 'city': 'Guoxing Township' },
    { 'id': 545, 'county': 'Nantou County', 'city': 'Puli Township' },
    { 'id': 546, 'county': 'Nantou County', 'city': "Ren'ai Township" },
    { 'id': 551, 'county': 'Nantou County', 'city': 'Mingjian Township' },
    { 'id': 552, 'county': 'Nantou County', 'city': 'Jiji Township' },
    { 'id': 553, 'county': 'Nantou County', 'city': 'Shuili Township' },
    { 'id': 555, 'county': 'Nantou County', 'city': 'Yuchi Township' },
    { 'id': 556, 'county': 'Nantou County', 'city': 'Xinyi Township' },
    { 'id': 557, 'county': 'Nantou County', 'city': 'Zhushan Township' },
    { 'id': 558, 'county': 'Nantou County', 'city': 'Lugu Township' },
    { 'id': 600, 'county': 'Chiayi City', 'city': 'East District' },
    { 'id': 600, 'county': 'Chiayi City', 'city': 'West District' },
    { 'id': 602, 'county': 'Chiayi County', 'city': 'Fanlu Township' },
    { 'id': 603, 'county': 'Chiayi County', 'city': 'Meishan Township' },
    { 'id': 604, 'county': 'Chiayi County', 'city': 'Zhuqi Township' },
    { 'id': 605, 'county': 'Chiayi County', 'city': 'Alishan Township' },
    { 'id': 606, 'county': 'Chiayi County', 'city': 'Zhongpu Township' },
    { 'id': 607, 'county': 'Chiayi County', 'city': 'Dapu Township' },
    { 'id': 608, 'county': 'Chiayi County', 'city': 'Shuishang Township' },
    { 'id': 611, 'county': 'Chiayi County', 'city': 'Lucao Township' },
    { 'id': 612, 'county': 'Chiayi County', 'city': 'Taibao City' },
    { 'id': 613, 'county': 'Chiayi County', 'city': 'Puzi City' },
    { 'id': 614, 'county': 'Chiayi County', 'city': 'Dongshi Township' },
    { 'id': 615, 'county': 'Chiayi County', 'city': 'Liujiao Township' },
    { 'id': 616, 'county': 'Chiayi County', 'city': 'Xingang Township' },
    { 'id': 621, 'county': 'Chiayi County', 'city': 'Minxiong Township' },
    { 'id': 622, 'county': 'Chiayi County', 'city': 'Dalin Township' },
    { 'id': 623, 'county': 'Chiayi County', 'city': 'Xikou Township' },
    { 'id': 624, 'county': 'Chiayi County', 'city': 'Yizhu Township' },
    { 'id': 625, 'county': 'Chiayi County', 'city': 'Budai Township' },
    { 'id': 630, 'county': 'Yunlin County', 'city': 'Dounan Township' },
    { 'id': 631, 'county': 'Yunlin County', 'city': 'Dapi Township' },
    { 'id': 632, 'county': 'Yunlin County', 'city': 'Huwei Township' },
    { 'id': 633, 'county': 'Yunlin County', 'city': 'Tuku Township' },
    { 'id': 634, 'county': 'Yunlin County', 'city': 'Baozhong Township' },
    { 'id': 635, 'county': 'Yunlin County', 'city': 'Dongshi Township' },
    { 'id': 636, 'county': 'Yunlin County', 'city': 'Taixi Township' },
    { 'id': 637, 'county': 'Yunlin County', 'city': 'Lunbei Township' },
    { 'id': 638, 'county': 'Yunlin County', 'city': 'Mailiao Township' },
    { 'id': 640, 'county': 'Yunlin County', 'city': 'Douliu City' },
    { 'id': 643, 'county': 'Yunlin County', 'city': 'Linnei Township' },
    { 'id': 646, 'county': 'Yunlin County', 'city': 'Gukeng Township' },
    { 'id': 647, 'county': 'Yunlin County', 'city': 'Citong Township' },
    { 'id': 648, 'county': 'Yunlin County', 'city': 'Xiluo Township' },
    { 'id': 649, 'county': 'Yunlin County', 'city': 'Erlun Township' },
    { 'id': 651, 'county': 'Yunlin County', 'city': 'Beigang Township' },
    { 'id': 652, 'county': 'Yunlin County', 'city': 'Shuilin Township' },
    { 'id': 653, 'county': 'Yunlin County', 'city': 'Kouhu Township' },
    { 'id': 654, 'county': 'Yunlin County', 'city': 'Sihu Township' },
    { 'id': 655, 'county': 'Yunlin County', 'city': 'Yuanchang Township' },
    { 'id': 700, 'county': 'Tainan City', 'city': 'West Central District' },
    { 'id': 701, 'county': 'Tainan City', 'city': 'East District' },
    { 'id': 702, 'county': 'Tainan City', 'city': 'South District' },
    { 'id': 704, 'county': 'Tainan City', 'city': 'North District' },
    { 'id': 708, 'county': 'Tainan City', 'city': 'Anping District' },
    { 'id': 709, 'county': 'Tainan City', 'city': 'Annan District' },
    { 'id': 710, 'county': 'Tainan City', 'city': 'Yongkang District' },
    { 'id': 711, 'county': 'Tainan City', 'city': 'Guiren District' },
    { 'id': 712, 'county': 'Tainan City', 'city': 'Xinhua District' },
    { 'id': 713, 'county': 'Tainan City', 'city': 'Zuozhen District' },
    { 'id': 714, 'county': 'Tainan City', 'city': 'Yujing District' },
    { 'id': 715, 'county': 'Tainan City', 'city': 'Nanxi District' },
    { 'id': 716, 'county': 'Tainan City', 'city': 'Nanhua District' },
    { 'id': 717, 'county': 'Tainan City', 'city': 'Rende District' },
    { 'id': 718, 'county': 'Tainan City', 'city': 'Guanmiao District' },
    { 'id': 719, 'county': 'Tainan City', 'city': 'Longqi District' },
    { 'id': 720, 'county': 'Tainan City', 'city': 'Guantian District' },
    { 'id': 721, 'county': 'Tainan City', 'city': 'Madou District' },
    { 'id': 722, 'county': 'Tainan City', 'city': 'Jiali District' },
    { 'id': 723, 'county': 'Tainan City', 'city': 'Xigang District' },
    { 'id': 724, 'county': 'Tainan City', 'city': 'Qigu District' },
    { 'id': 725, 'county': 'Tainan City', 'city': 'Jiangjun District' },
    { 'id': 726, 'county': 'Tainan City', 'city': 'Xuejia District' },
    { 'id': 727, 'county': 'Tainan City', 'city': 'Beimen District' },
    { 'id': 730, 'county': 'Tainan City', 'city': 'Xinying District' },
    { 'id': 731, 'county': 'Tainan City', 'city': 'Houbi District' },
    { 'id': 732, 'county': 'Tainan City', 'city': 'Baihe District' },
    { 'id': 733, 'county': 'Tainan City', 'city': 'Dongshan District' },
    { 'id': 734, 'county': 'Tainan City', 'city': 'Liujia District' },
    { 'id': 735, 'county': 'Tainan City', 'city': 'Xiaying District' },
    { 'id': 736, 'county': 'Tainan City', 'city': 'Liuying District' },
    { 'id': 737, 'county': 'Tainan City', 'city': 'Yanshui District' },
    { 'id': 741, 'county': 'Tainan City', 'city': 'Shanhua District' },
    { 'id': 742, 'county': 'Tainan City', 'city': 'Danei District' },
    { 'id': 743, 'county': 'Tainan City', 'city': 'Shanshang District' },
    { 'id': 744, 'county': 'Tainan City', 'city': 'Xinshi District' },
    { 'id': 745, 'county': 'Tainan City', 'city': 'Anding District' },
    { 'id': 800, 'county': 'Kaohsiung City', 'city': 'Xinxing District' },
    { 'id': 801, 'county': 'Kaohsiung City', 'city': 'Qianjin District' },
    { 'id': 802, 'county': 'Kaohsiung City', 'city': 'Lingya District' },
    { 'id': 803, 'county': 'Kaohsiung City', 'city': 'Yancheng District' },
    { 'id': 804, 'county': 'Kaohsiung City', 'city': 'Gushan District' },
    { 'id': 805, 'county': 'Kaohsiung City', 'city': 'Qijin District' },
    { 'id': 806, 'county': 'Kaohsiung City', 'city': 'Qianzhen District' },
    { 'id': 807, 'county': 'Kaohsiung City', 'city': 'Sanmin District' },
    { 'id': 811, 'county': 'Kaohsiung City', 'city': 'Nanzi District' },
    { 'id': 812, 'county': 'Kaohsiung City', 'city': 'Xiaogang District' },
    { 'id': 813, 'county': 'Kaohsiung City', 'city': 'Zouying District' },
    { 'id': 814, 'county': 'Kaohsiung City', 'city': 'Renwu District' },
    { 'id': 815, 'county': 'Kaohsiung City', 'city': 'Dashe District' },
    { 'id': 820, 'county': 'Kaohsiung City', 'city': 'Gangshan District' },
    { 'id': 821, 'county': 'Kaohsiung City', 'city': 'Luzhu District' },
    { 'id': 822, 'county': 'Kaohsiung City', 'city': 'Alian District' },
    { 'id': 823, 'county': 'Kaohsiung City', 'city': 'Tianliao District' },
    { 'id': 824, 'county': 'Kaohsiung City', 'city': 'Yanchao District' },
    { 'id': 825, 'county': 'Kaohsiung City', 'city': 'Qiaotou District' },
    { 'id': 826, 'county': 'Kaohsiung City', 'city': 'Ziguan District' },
    { 'id': 827, 'county': 'Kaohsiung City', 'city': 'Mituo District' },
    { 'id': 828, 'county': 'Kaohsiung City', 'city': "Yong'an District" },
    { 'id': 829, 'county': 'Kaohsiung City', 'city': 'Hunei District' },
    { 'id': 830, 'county': 'Kaohsiung City', 'city': 'Fengshan District' },
    { 'id': 831, 'county': 'Kaohsiung City', 'city': 'Daliao District' },
    { 'id': 832, 'county': 'Kaohsiung City', 'city': 'Linyuan District' },
    { 'id': 833, 'county': 'Kaohsiung City', 'city': 'Niaosong District' },
    { 'id': 840, 'county': 'Kaohsiung City', 'city': 'Dashu District' },
    { 'id': 842, 'county': 'Kaohsiung City', 'city': 'Qishan District' },
    { 'id': 843, 'county': 'Kaohsiung City', 'city': 'Meinong District' },
    { 'id': 844, 'county': 'Kaohsiung City', 'city': 'Liugui District' },
    { 'id': 845, 'county': 'Kaohsiung City', 'city': 'Neimen District' },
    { 'id': 846, 'county': 'Kaohsiung City', 'city': 'Shanlin District' },
    { 'id': 847, 'county': 'Kaohsiung City', 'city': 'Jiaxian District' },
    { 'id': 848, 'county': 'Kaohsiung City', 'city': 'Taoyuan District' },
    { 'id': 849, 'county': 'Kaohsiung City', 'city': 'Namaxia District' },
    { 'id': 851, 'county': 'Kaohsiung City', 'city': 'Maolin District' },
    { 'id': 852, 'county': 'Kaohsiung City', 'city': 'Qieding District' },
    { 'id': 817, 'county': 'Kaohsiung City', 'city': 'Dongsha Islands' },
    { 'id': 819, 'county': 'Kaohsiung City', 'city': 'Spratly Islands' },
    { 'id': 880, 'county': 'Penghu County', 'city': 'Magong City' },
    { 'id': 881, 'county': 'Penghu County', 'city': 'Xiyu Township' },
    { 'id': 882, 'county': 'Penghu County', 'city': "Wang'an Township" },
    { 'id': 883, 'county': 'Penghu County', 'city': 'Qimei Township' },
    { 'id': 884, 'county': 'Penghu County', 'city': 'Baisha Township' },
    { 'id': 885, 'county': 'Penghu County', 'city': 'Huxi Township' },
    { 'id': 900, 'county': 'Pingtung County', 'city': 'Pingdong City' },
    { 'id': 901, 'county': 'Pingtung County', 'city': 'Sandimen Township' },
    { 'id': 902, 'county': 'Pingtung County', 'city': 'Wutai Township' },
    { 'id': 903, 'county': 'Pingtung County', 'city': 'Majia Township' },
    { 'id': 904, 'county': 'Pingtung County', 'city': 'Jiuru Township' },
    { 'id': 905, 'county': 'Pingtung County', 'city': 'Ligang Township' },
    { 'id': 906, 'county': 'Pingtung County', 'city': 'Gaoshu Township' },
    { 'id': 907, 'county': 'Pingtung County', 'city': 'Yanpu Township' },
    { 'id': 908, 'county': 'Pingtung County', 'city': 'Changzhi Township' },
    { 'id': 909, 'county': 'Pingtung County', 'city': 'Linluo Township' },
    { 'id': 911, 'county': 'Pingtung County', 'city': 'Zhutian Township' },
    { 'id': 912, 'county': 'Pingtung County', 'city': 'Neipu Township' },
    { 'id': 913, 'county': 'Pingtung County', 'city': 'Wandan Township' },
    { 'id': 920, 'county': 'Pingtung County', 'city': 'Chaozhou Township' },
    { 'id': 921, 'county': 'Pingtung County', 'city': 'Taiwu Township' },
    { 'id': 922, 'county': 'Pingtung County', 'city': 'Laiyi Township' },
    { 'id': 923, 'county': 'Pingtung County', 'city': 'Wanluan Township' },
    { 'id': 924, 'county': 'Pingtung County', 'city': 'Kanding Township' },
    { 'id': 925, 'county': 'Pingtung County', 'city': 'Xinpi Township' },
    { 'id': 926, 'county': 'Pingtung County', 'city': 'Nanzhou Township' },
    { 'id': 927, 'county': 'Pingtung County', 'city': 'Linbian Township' },
    { 'id': 928, 'county': 'Pingtung County', 'city': 'Donggang Township' },
    { 'id': 929, 'county': 'Pingtung County', 'city': 'Liuqiu Township' },
    { 'id': 931, 'county': 'Pingtung County', 'city': 'Jiadong Township' },
    { 'id': 932, 'county': 'Pingtung County', 'city': 'Xinyuan Township' },
    { 'id': 940, 'county': 'Pingtung County', 'city': 'Fangliao Township' },
    { 'id': 941, 'county': 'Pingtung County', 'city': 'Fangshan Township' },
    { 'id': 942, 'county': 'Pingtung County', 'city': 'Chunri Township' },
    { 'id': 943, 'county': 'Pingtung County', 'city': 'Shizi Township' },
    { 'id': 944, 'county': 'Pingtung County', 'city': 'Checheng Township' },
    { 'id': 945, 'county': 'Pingtung County', 'city': 'Mudan Township' },
    { 'id': 946, 'county': 'Pingtung County', 'city': 'Hengchu Township' },
    { 'id': 947, 'county': 'Pingtung County', 'city': 'Manzhou Township' },
    { 'id': 950, 'county': 'Taitung County', 'city': 'Taitung City' },
    { 'id': 951, 'county': 'Taitung County', 'city': 'Ludao Township' },
    { 'id': 952, 'county': 'Taitung County', 'city': 'Lanyu Township' },
    { 'id': 953, 'county': 'Taitung County', 'city': 'Yangping Township' },
    { 'id': 954, 'county': 'Taitung County', 'city': 'Beinan Township' },
    { 'id': 955, 'county': 'Taitung County', 'city': 'Luye Township' },
    { 'id': 956, 'county': 'Taitung County', 'city': 'Guanshan Township' },
    { 'id': 957, 'county': 'Taitung County', 'city': 'Haiduan Township' },
    { 'id': 958, 'county': 'Taitung County', 'city': 'Chishang Township' },
    { 'id': 959, 'county': 'Taitung County', 'city': 'Donghe Township' },
    { 'id': 961, 'county': 'Taitung County', 'city': 'Chenggong Township' },
    { 'id': 962, 'county': 'Taitung County', 'city': 'Changbin Township' },
    { 'id': 963, 'county': 'Taitung County', 'city': 'Taimali Township' },
    { 'id': 964, 'county': 'Taitung County', 'city': 'Jinfeng Township' },
    { 'id': 965, 'county': 'Taitung County', 'city': 'Dawu Township' },
    { 'id': 966, 'county': 'Taitung County', 'city': 'Daren Township' },
    { 'id': 970, 'county': 'Hualien County', 'city': 'Hualien City' },
    { 'id': 971, 'county': 'Hualien County', 'city': 'Xincheng Township' },
    { 'id': 972, 'county': 'Hualien County', 'city': 'Xiulin Township' },
    { 'id': 973, 'county': 'Hualien County', 'city': "Ji'an Township" },
    { 'id': 974, 'county': 'Hualien County', 'city': 'Shoufeng Township' },
    { 'id': 975, 'county': 'Hualien County', 'city': 'Fenglin Township' },
    { 'id': 976, 'county': 'Hualien County', 'city': 'Guangfu Township' },
    { 'id': 977, 'county': 'Hualien County', 'city': 'Fengbin Township' },
    { 'id': 978, 'county': 'Hualien County', 'city': 'Ruisui Township' },
    { 'id': 979, 'county': 'Hualien County', 'city': 'Wanrong Township' },
    { 'id': 981, 'county': 'Hualien County', 'city': 'Yuli Township' },
    { 'id': 982, 'county': 'Hualien County', 'city': 'Zhuoxi Township' },
    { 'id': 983, 'county': 'Hualien County', 'city': 'Fuli Township' },
    { 'id': 890, 'county': 'Kinmen County', 'city': 'Jinsha Township' },
    { 'id': 891, 'county': 'Kinmen County', 'city': 'Jinhu Township' },
    { 'id': 892, 'county': 'Kinmen County', 'city': 'Jinning Township' },
    { 'id': 893, 'county': 'Kinmen County', 'city': 'Jincheng Township' },
    { 'id': 894, 'county': 'Kinmen County', 'city': 'Lieyu Township' },
    { 'id': 896, 'county': 'Kinmen County', 'city': 'Wuqiu Township' },
    { 'id': 209, 'county': 'Lienchiang County', 'city': 'Nangan Township' },
    { 'id': 210, 'county': 'Lienchiang County', 'city': 'Beigan Township' },
    { 'id': 211, 'county': 'Lienchiang County', 'city': 'Juguan Township' },
    { 'id': 212, 'county': 'Lienchiang County', 'city': 'Dongyin Township' }
]
